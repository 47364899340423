import BaseAction from '@/actions/base_action.js'

export default class extends BaseAction {
  execute(properties, component) {
    const hillChart = GLib.component.findById('hill_chart')
    const points = hillChart.getSnapshotPoints();
    const projectId = properties.formData["snapshot[project_id]"]
    const description = properties.formData["snapshot[description]"]

    const snapshotItems = points.map((point) => {
      return {
        x_axis: point.x,
        title: point.title,
        scope_card_uuid: point.key
      }
    })

    this.constructor.promise(description, snapshotItems, projectId, component)
  }

  static async promise(description, snapshotItems, projectId, component) {
    GLib.action.execute({
      action: "http/post",
      url: $tpu.urls.snapshots(projectId),
      formData: {
        board_id: $tpu.urls.boardId,
        snapshot: {
          description: description,
          snapshot_items_attributes: snapshotItems
        }
      },
    }, component)
  }
}
